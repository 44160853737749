<template>
  <div class="py-5 mt-5 d-flex flex-column">
    <button
      class="
        my-3
        mx-3
        h-max-content h-3-5-r
        rounded
        bg-white
        shadow-sm
        pointer
        d-flex
        justify-content-between
        text-grey-medium
        w-auto
      "
      @click="$router.push({ query: { mode: 'view' } })"
    >
      <span class="mx-3 align-self-center">Modificar intervalos</span>
      <div class="align-self-center">
        <inline-svg
          class="align-self-center"
          src="/flecha-ladoder.svg"
          width="30px"
          height="30px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
    </button>

    <button
      class="
        my-3
        mx-3
        h-max-content h-3-5-r
        rounded
        bg-white
        shadow-sm
        pointer
        d-flex
        justify-content-between
        text-grey-medium
        w-auto
      "
      @click="$router.push({ query: { listing: true } })"
    >
      <span class="mx-3 align-self-center">Notificaciones</span>
      <inline-svg
        class="align-self-center"
        src="/flecha-ladoder.svg"
        width="30px"
        height="30px"
        fill="white"
        aria-label="Loading..."
      />
    </button>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
