<template>
  <div class="d-flex my-4 flex-column">
    <div class="align-self-start d-flex w-100 justify-content-between">
      <inline-svg
        class="align-self-start t-rotate-180"
        src="/flecha-ladoder.svg"
        width="45px"
        height="45px"
        fill="white"
        aria-label="Loading..."
        @click="$router.push({ query: {} })"
      />

      <inline-svg
        class="align-self-end t-rotate-180"
        src="/filters-noti.svg"
        width="30px"
        height="30px"
        :fill="isFilterApplied ? 'green' : 'black'"
        aria-label="Loading..."
        @click="showFilters = !showFilters"
      />
    </div>
    <notification-card
      v-for="noti in notifications"
      :key="noti._id"
      :notification="noti"
    />
    <transition name="slide">
      <filters-notifications
        :style="{ position: 'fixed', right: '0', bottom: '0', width: '100%' }"
        v-if="showFilters"
        :filters="filtersApplied"
        @setFilter="filterListing"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FiltersNotifications from "./FiltersNotifications.vue"

import NotificationCard from "./NotificationCard.vue"
const baseFilters = {
  measurement_type: null,
  boxID: null,
  type: null,
  status: null
}
export default {
  data() {
    return {
      showFilters: false,
      filtersApplied: baseFilters
    }
  },
  components: {
    NotificationCard,
    FiltersNotifications
  },
  computed: {
    ...mapGetters(["notifications"]),
    isFilterApplied() {
      const rta = Object.keys(this.filtersApplied).map((k) => {
        return this.filtersApplied[k] ? true : false
      })
      return rta.reduce((acc, cur) => {
        if (cur || acc) {
          return true
        } else return false
      }, false)
    }
  },

  methods: {
    ...mapActions(["FILTER_NOTIFICATIONS"]),
    async filterListing(obj) {
      console.log(obj)
      this.filtersApplied = Object.assign({}, baseFilters, obj)
      this.showFilters = false
      await this.FILTER_NOTIFICATIONS(this.filtersApplied)
    }
  }
}
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: margin-bottom 0.4s ease-out;
}

.slide-enter,
.slide-leave-to {
  margin-bottom: -200px;
}

.slide-enter-to,
.slide-leave {
  margin-bottom: 0px;
}
</style>
