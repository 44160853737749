<template>
  <div class="rounded bg-white my-2 d-flex">
    <div
      class="h-auto"
      :style="{
        width: '10px',
        'background-color': alertColor,
        color: alertColor
      }"
    >
      c
    </div>
    <div class="d-flex flex-column w-100">
      <div
        class="d-flex brown-wGreyMedium justify-content-around w-100 py-2"
        :style="{ 'border-bottom': '0.08rem solid #B7B7B7' }"
      >
        <span> Zona: {{ notification.box.serial }} </span><span>tipo</span>
      </div>
      <div class="d-flex justify-content-around py-1">
        <div class="d-flex flex-column align-self-start">
          <h4 class="font-rem-0-9" :style="{ color: colorComputed }">
            {{ notification.title }}
          </h4>
          <span class="brown-wGreyMedium font-rem-0-8">{{ dateComputed }}</span>
        </div>
        <div>
          <inline-svg
            class="align-self-center"
            :src="srcComputed"
            width="20px"
            height="20px"
            fill="black"
            aria-label="Loading..."
          />
          <span
            v-if="notification.type == 'range'"
            class="py-2 font-rem-0-8"
            :style="{ color: colorComputed }"
            >{{ Number(notification.value).toFixed(1) }}
            {{
              notification.measurement_type.symbol
                ? notification.measurement_type.symbol
                : "n/a"
            }}</span
          >
        </div>
      </div>
      <div class="p-2 font-rem-0-8 brown-green-dark-regular">
        {{ notification.body }}
      </div>
      <div
        class="
          p-2
          font-rem-0-8
          brown-green-dark-regular
          d-flex
          justify-content-around
        "
      >
        <span>count: {{ notification.count }}</span>
        <span>status: {{ notification.status }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["notification"],
  computed: {
    srcComputed() {
      const tempCase =
        this.notification && this.notification.measurement_type
          ? this.notification.measurement_type.name
          : this.notification.type
      let src = ""
      switch (tempCase) {
        case "light":
          src = "/luz.svg"
          break
        case "temperature":
          src = "/temperatura.svg"
          break
        case "humidity":
          src = "/humedad.svg"
          break
        case "connection":
          src = "/wifi-off.svg"
          break
      }
      return src
    },
    dateComputed() {
      return this.notification.updatedAt
        ? new Date(this.notification.updatedAt).toLocaleString().split(",")[0]
        : ""
    },
    colorComputed() {
      const tempCase =
        this.notification && this.notification.measurement_type
          ? this.notification.measurement_type.name
          : this.notification.type
      if (tempCase == "humidity") {
        return "#A8D5DF"
      } else if (tempCase == "temperature") {
        return "#EFC59F"
      } else if (tempCase == "connection") {
        return "gray"
      } else {
        return "#FDFDFD"
      }
    },
    alertColor() {
      if (this.notification.type == "range") {
        if (this.notification.critical_value_top <= this.notification.value) {
          return "red"
        } else {
          return "blue"
        }
      } else {
        return "gray"
      }
    }
  }
}
</script>

<style></style>
