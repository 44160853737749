<template>
  <div class="py-5 px-2 d-flex flex-column">
    <div class="d-flex justify-content-start">
      <div class="align-self-center" @click="$router.push(-1)">
        <inline-svg
          class="align-self-center t-rotate-180"
          src="/flecha-ladoder.svg"
          width="45px"
          height="45px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
      <div
        class="brown-green-bold font-rem-1-25 align-self-center"
        @click="$router.push({ query: { mode: 'edit' } })"
      >
        Intervalos
      </div>
    </div>
    <div
      class="
        px-2
        py-3
        my-3
        mx-4
        h-max-content
        brown-wGreyMedium
        rounded
        bg-white
        shadow-sm
        pointer
        d-flex
        flex-column
        justify-content-between
        text-grey-medium
        w-auto
        shadow
      "
    >
      <span class="py-3 mx-3 align-self-start brown-green-bold"
        >Temperatura</span
      >

      <div class="d-flex px-2">
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border py-2 mx-3"
          v-model="minT"
          placeholder="minimo"
        />
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border py-2 mx-3"
          v-model="maxT"
          placeholder="maximo"
        />
        <input class="align-self-center" v-model="activeT" type="checkbox" />
      </div>

      <span class="py-3 mx-3 align-self-start brown-green-bold">Humedad</span>

      <div class="d-flex px-2">
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border py-2 mx-3"
          v-model="minH"
          placeholder="minimo"
        />
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border py-2 mx-3"
          v-model="maxH"
          placeholder="maximo"
        />
        <input class="align-self-center" v-model="activeH" type="checkbox" />
      </div>
      <div class="align-self-center"></div>
    </div>
    <button
      class="
        py-2
        px-3
        mt-4
        mx-5
        w-100
        h-50
        rounded
        bg-dark-green
        shadow-sm
        pointer
        d-flex
        align-self-center
        justify-content-center
        text-white
        w-fit-content
      "
      @click="updateNotifications"
    >
      <span class="align-self-center"> Guardar </span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
export default {
  computed: {
    ...mapGetters(["boxBySerial"]),

    activeT: {
      get() {
        const box = this.boxBySerial(this.$route.query.serial)
        const portData = box.port_data.find((p) => p.label == "T")
        if (portData && portData.active) {
          return portData.active
        } else {
          return false
        }
      },
      set(value) {
        const payload = {
          serial: this.$route.query.serial,
          label: "T",
          key: "active",
          value: value
        }

        return this.UPDATE_BOX_PORT(payload)
      }
    },
    activeH: {
      get() {
        const box = this.boxBySerial(this.$route.query.serial)
        const portData = box.port_data.find((p) => p.label == "H")
        if (portData && portData.active) {
          return portData.active
        } else {
          return false
        }
      },
      set(value) {
        const payload = {
          serial: this.$route.query.serial,
          label: "H",
          key: "active",
          value: value
        }

        return this.UPDATE_BOX_PORT(payload)
      }
    },
    minT: {
      get() {
        const box = this.boxBySerial(this.$route.query.serial)
        const portData = box.port_data.find((p) => p.label == "T")
        if (portData && portData.critical_value_bottom) {
          return portData.critical_value_bottom
        } else {
          return null
        }
      },
      set(value) {
        const payload = {
          serial: this.$route.query.serial,
          label: "T",
          key: "critical_value_bottom",
          value: value
        }

        return this.UPDATE_BOX_PORT(payload)
      }
    },
    maxT: {
      get() {
        const box = this.boxBySerial(this.$route.query.serial)
        const portData = box.port_data.find((p) => p.label == "T")
        if (portData && portData.critical_value_top) {
          return portData.critical_value_top
        } else {
          return null
        }
      },
      set(value) {
        const payload = {
          serial: this.$route.query.serial,
          label: "T",
          key: "critical_value_top",
          value: value
        }

        return this.UPDATE_BOX_PORT(payload)
      }
    },
    minH: {
      get() {
        const box = this.boxBySerial(this.$route.query.serial)
        const portData = box.port_data.find((p) => p.label == "H")
        if (portData && portData.critical_value_bottom) {
          return portData.critical_value_bottom
        } else {
          return null
        }
      },
      set(value) {
        const payload = {
          serial: this.$route.query.serial,
          label: "H",
          key: "critical_value_bottom",
          value: value
        }

        return this.UPDATE_BOX_PORT(payload)
      }
    },
    maxH: {
      get() {
        const box = this.boxBySerial(this.$route.query.serial)
        const portData = box.port_data.find((p) => p.label == "H")
        if (portData && portData.critical_value_top) {
          return portData.critical_value_top
        } else {
          return null
        }
      },
      set(value) {
        const payload = {
          serial: this.$route.query.serial,
          label: "H",
          key: "critical_value_top",
          value: value
        }

        return this.UPDATE_BOX_PORT(payload)
      }
    }
  },
  methods: {
    ...mapMutations(["UPDATE_BOX_PORT"]),
    ...mapActions(["updatePort"]),
    updateNotifications() {
      const body = {
        serial: this.$route.query.serial,
        H: {
          critical_value_bottom: this.minH,
          active: this.activeH,
          critical_value_top: this.maxH
        },
        T: {
          critical_value_bottom: this.minT,
          active: this.activeT,
          critical_value_top: this.maxT
        }
      }

      this.updatePort(body)
    }
  }
}
</script>

<style></style>
