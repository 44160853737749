<template>
  <div class="bg-green d-flex z-100 flex-column w-100 font-rem-1-2 p-4 rounded">
    Filtro
    <div>Tipo Medida:</div>
    <select
      @change="appFilter($event, 'measurement_type')"
      v-model="filters.measurement_type"
    >
      <option :value="null">Selecione</option>
      <option value="5e69e99f66f75007f68e1c85">Temperatura</option>
      <option value="5e69e9d266f75007f68e1c87">Humedad</option>
    </select>
    <br />
    <div>Caja:</div>
    <select @change="appFilter($event, 'boxID')" v-model="filters.boxID">
      <option :value="null">Selecione</option>
      <option v-for="box in objects" :key="box._id" :value="box._id">
        {{ box.serial }}
      </option>
    </select>
    <br />
    <div>Tipo:</div>
    <select @change="appFilter($event, 'type')" v-model="filters.type">
      <option :value="null">Selecione</option>
      <option value="range">Rango</option>
      <option value="connection">Conexion</option>
    </select>
    <br />
    <div>Estado:</div>
    <select @change="appFilter($event, 'status')" v-model="filters.status">
      <option :value="null">Selecione</option>
      <option value="open">Abierta</option>
      <option value="closed">Cerrada</option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: ["filters"],
  computed: {
    ...mapGetters(["objects"])
  },
  methods: {
    appFilter(event, code) {
      console.log(event.target.value, code)
      const evrnt = {}
      evrnt[code] = event.target.value
      this.$emit("setFilter", evrnt)
    }
  }
}
</script>

<style></style>
