<template>
  <div class="container">
    <div
      v-if="
        $route.query &&
        !$route.query.mode &&
        !$route.query.single &&
        !$route.query.serial &&
        !$route.query.listing
      "
    >
      <notifications-options />
    </div>
    <div
      v-else-if="$route.query.mode == 'view'"
      class="d-flex my-4 flex-column"
    >
      <div class="align-self-start" @click="$router.push({ query: {} })">
        <inline-svg
          class="align-self-center t-rotate-180"
          src="/flecha-ladoder.svg"
          width="45px"
          height="45px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
      <box-card v-for="box in objects" :key="box._id" :box="box" />
    </div>
    <div v-else-if="$route.query && $route.query.single && $route.query.serial">
      <notifications-editor />
    </div>
    <div v-else-if="$route.query && $route.query.listing">
      <notifications-listing />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import NotificationsEditor from "./NotificationsEditor.vue"
import NotificationsListing from "./NotificationsListing.vue"
import NotificationsOptions from "./NotificationsOptions.vue"

//import ProfileTemplate from "./ProfileTemplate.vue"
export default {
  components: {
    "box-card": () => import("@/components/Boxes/BoxCard"),
    NotificationsOptions,
    NotificationsEditor,
    NotificationsListing
  },
  computed: {
    ...mapGetters(["objects"])
  }
}
</script>

<style></style>
